import React from 'react'
import { Link } from 'gatsby'

import { getFormattedDate, slugify } from '../utils/helpers'

export const PostSidebar = ({ tags = [], date, categories = [] }) => {
  const category = categories?.filter((category) => category !== 'Highlight')
  const formattedDate = getFormattedDate(date)

  return (
    <aside className="post-sidebar">
      <div className="post-sidebar-card">
        <h2>Makasih!</h2>
        <p>
          Makasih udah baca!
        </p>
        <p>
          Mohon maaf apabila ada salah kata dan cara penyampaian, kepada Allah SWT saya mohon ampun.
        </p>
      </div>

      <div className="post-sidebar-card">
        <h2>Detail Post</h2>
        <ul>
          <li>
            <strong>Diterbitkan: </strong> {formattedDate}
          </li>
          <li>
            <strong>Kategori: </strong>
            <Link to={`/categories/${slugify(category)}`}>{category}</Link>
          </li>
        </ul>

        <h2>Tag</h2>
        <div className="tags">
          {tags.map((tag) => {
            return (
              <Link
                key={tag}
                to={`/tags/${slugify(tag)}`}
                className="tag"
                activeClassName="active"
              >
                {tag}
              </Link>
            )
          })}
        </div>
      </div>
    </aside>
  )
}
